import React from 'react';
import { FeedbackNok, FeedbackOk } from 'src/Assets';

import styles from './FeedbackInput.module.scss';
import { Props } from './FeedbackInput.types';

const FeedbackInput = ({ onFeedbackOk, onFeedbackNok }: Props) => {
  return (
    <div className={styles.container}>
      <div>Avis</div>
      <div className={styles.icons}>
        <FeedbackOk
          onClick={event => {
            event.stopPropagation();
            onFeedbackOk();
          }}
        />
        <FeedbackNok
          onClick={event => {
            event.stopPropagation();
            onFeedbackNok();
          }}
        />
      </div>
    </div>
  );
};

export default FeedbackInput;
