import React, { useEffect } from 'react';

import styles from './DocumentsList.module.scss';
import { documentStructure } from 'src/Services/API/Documents';
import Subtitle from 'src/Components/Subtitle';
import { useHistory } from 'react-router-dom';
import HorizontalPicker from 'src/Components/HorizontalPicker';
import { NeovieButton } from 'src/Components/Button/Button.component';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const DocumentsList = () => {
  const { trackPageView } = useMatomo();
  const history = useHistory();

  useEffect(() => {
    trackPageView({ documentTitle: 'document list page' });
  }, [trackPageView]);

  return (
    <div className={styles.container}>
      <div className={styles.title} onClick={() => history.push('/')}>
        Fiches synthèse
      </div>
      {Object.entries(documentStructure).map(category => (
        <div className={styles.categoryContainer} key={category[1].title}>
          {category[1].children ? (
            <div key={category[1].title}>
              <Subtitle text={category[1].title} />
              <div className={styles.subCategories}>
                <HorizontalPicker
                  choices={Object.entries(category[1].children).map(subCategory => ({
                    value: subCategory[1].title,
                    onClick: () => history.push(`/documents/${category[0]}/${subCategory[0]}`),
                  }))}
                />
              </div>
            </div>
          ) : (
            <div
              className={styles.noSubcategory}
              onClick={() => history.push(`/documents/${category[0]}`)}
            >
              <Subtitle key={category[1].title} text={category[1].title} />
              <div className={styles.seeAll}>Tout voir</div>
            </div>
          )}
        </div>
      ))}
      <NeovieButton onClick={() => history.push('/documents/all')}>
        Les fiches par ordre alphabétique
      </NeovieButton>
    </div>
  );
};

export default DocumentsList;
