import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { NeovieButton, NeovieErrorButton } from 'src/Components/Button/Button.component';
import { useFetchProjectId } from 'src/Hooks/IlluinSearch/useFetchProjectId';
import { useLocalDocumentInfo } from 'src/Hooks/IlluinSearch/useLocalDocumentInfo';
import { QueryKeys } from 'src/Hooks/types';
import { db } from 'src/Services/DB';
import humanFileSize from 'src/Utils/humanfilesize';

import styles from './InfoTab.module.scss';

const InfoTab = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const info = useLocalDocumentInfo();
  const queryClient = useQueryClient();
  const projectId = useFetchProjectId();

  const links = [
    {
      label: "Contacter l'équipe Neovie",
      url: 'https://forms.gle/SZpdBiT8uatFUL77A',
    },
    {
      label: 'CRISTAL',
      url:
        'https://www.sipg.sante.fr/portail/plugins/BiomedecinePortalPlugin/jsp/privateLogin.jsp?redirect=https%3A%2F%2Fwww.sipg.sante.fr%2Fportail%2Fjcms%2Ftbe_1953788%3Fredirect%3Dhttps%253A%252F%252Fwww.sipg.sante.fr%252Fportail%252Fjcms%252F',
    },
    { label: 'ABM', url: 'https://www.agence-biomedecine.fr/Site-des-professionnels' },
    { label: "Don d'organes", url: 'https://www.dondorganes.fr/' },
    {
      label: 'Arrêté 29/10/2015',
      url:
        'https://www.agence-biomedecine.fr/IMG/pdf/joe_20151125_0273_00225571244598013941397.pdf',
    },
    {
      label: 'Entretien Proches - Arrêté 16/07/2016',
      url:
        'https://www.agence-biomedecine.fr/IMG/pdf/20160825_rbp_entretien_prelevement_organes.pdf',
    },
    {
      label: "Recommandations sur l'abord des proches",
      url:
        'https://www.agence-biomedecine.fr/IMG/pdf/recommandations-sur-l-abord-des-proches-de-sujets-en-etat-de-mort-encephalique-en-vue-d-un-prelevement-d-organes-et-de-tissus.pdf',
    },
    {
      label: "Recommandation sur les techniques pour l'EME",
      url:
        'https://www.agence-biomedecine.fr/IMG/pdf/recommandations-techniques-concernant-le-donneur-en-etat-de_mort-encephalique.pdf',
    },
    {
      label: 'Application LifePort - Android',
      url: 'https://play.google.com/store/apps/details?id=org.nativescript.LifePort&hl=en&gl=US',
    },
    {
      label: 'Application LifePort - IoS',
      url: 'https://apps.apple.com/us/app/lifeport/id1462706243',
    },
    { label: 'SFAR', url: 'https://sfar.org/' },
    { label: 'SRLF', url: 'https://www.srlf.org/' },
    { label: 'SFMU', url: 'https://www.sfmu.org/fr/' },
  ];

  useEffect(() => {
    trackPageView({ documentTitle: 'info page' });
  }, [trackPageView]);
  return (
    <div className={styles.container}>
      <div className={styles.title}>Liens</div>
      <div className={styles.buttons}>
        {links.map(link => (
          <NeovieButton
            onClick={() => {
              trackEvent({ category: 'button click', action: link.label });
              window.open(link.url, '_blank');
            }}
          >
            {link.label}
          </NeovieButton>
        ))}
        <NeovieErrorButton
          onClick={async () => {
            await db.table('documents').clear();
            await db.table('documentData').clear();
            await queryClient.invalidateQueries([QueryKeys.listDocuments, projectId]);
            await queryClient.invalidateQueries(QueryKeys.downloadDocuments);
          }}
        >
          Effacer le cache
        </NeovieErrorButton>
      </div>
      <div className={styles.stats}>{`${info.count} document(s) accessible(s) hors-ligne`}</div>
      <div className={styles.stats}>{`taille sur le disque ${humanFileSize(info.size ?? 0)}`}</div>
      <div
        className={styles.stats}
      >{`version de l'application :  ${process.env.REACT_APP_VERSION}`}</div>
    </div>
  );
};

export default InfoTab;
