import { useLiveQuery } from 'dexie-react-hooks';

import { db } from 'src/Services/DB';

export const useLocalDocumentInfo = () => {
  const documents = useLiveQuery(() => db.documentData.toArray() ?? []);
  return {
    count: documents?.filter(doc => doc.data && doc.data.size > 0).length,
    size: documents?.reduce((previous, current) => previous + (current.data?.size ?? 0), 0),
  };
};
