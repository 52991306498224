import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useState, useRef, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import LoadingAnimation from 'src/Components/LoadingAnimation';
import SearchResult from 'src/Components/SearchResult';
import Subtitle from 'src/Components/Subtitle';
import Tips from 'src/Components/Tips';
import { useDocumentSearch, useListLocalDocuments } from 'src/Hooks/IlluinSearch';
import { MutationKeys } from 'src/Hooks/types';
import { SearchAnswer, SearchResponse } from 'src/Services/API';
import { Croix, Loupe } from '../../Assets';

import styles from './SearchTab.module.scss';

const SearchTab = () => {
  const { trackPageView } = useMatomo();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    const previousResearch = queryClient
      .getMutationCache()
      .getAll()
      .filter(mutation => mutation.options.mutationKey?.[0] === MutationKeys.documentSearch)
      ?.slice(-1)[0];

    if (previousResearch !== undefined) {
      setSearchText(
        ((previousResearch.state?.data as SearchResponse)?.data?.question?.text as
          | string
          | undefined) ?? ''
      );
      setParagraphs((previousResearch.state?.data as SearchResponse)?.data?.paragraphs);
    }
  }, [queryClient]);

  const localDocuments = useListLocalDocuments();

  const [searchText, setSearchText] = useState('');
  const [paragraphs, setParagraphs] = useState<SearchAnswer[]>();
  const [neverSearched, setNeverSearched] = useState(true);
  const documentSearch = useDocumentSearch({
    onSuccess: data => {
      if (data.data.question.original) {
        setSearchText(data.data.question.original);
        setParagraphs(data.data.paragraphs);
      }
    },
  });

  useEffect(() => {
    trackPageView({ documentTitle: 'search page' });
  }, [trackPageView]);

  return (
    <div className={styles.container}>
      <form action=".">
        <div className={styles.title}>Recherche</div>
        <div className={styles.searchField}>
          <Loupe className={styles.textInputLeftIcon} />
          <input
            ref={inputRef}
            name="search"
            key={'searchInput'}
            placeholder="votre question en langage courant"
            value={searchText}
            className={styles.textInput}
            onChange={event => setSearchText(event.target.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                setNeverSearched(false);
                documentSearch.mutate(searchText);
                inputRef?.current?.blur();
              }
            }}
          />
          {searchText.length > 0 && (
            <Croix className={styles.inputCross} onClick={() => setSearchText('')} />
          )}
        </div>
      </form>
      {(paragraphs?.length ?? 0) > 0 && <Subtitle text="les meilleurs résultats" />}

      <div className={styles.results}>
        {documentSearch.isError && (
          <div>
            <div className={styles.offlineTitle}>Oups !</div>
            <div className={styles.offlineText}>
              La recherche n'a pas aboutie. Veuillez vérifier votre connexion internet.
            </div>
          </div>
        )}
        {documentSearch.isLoading && (
          <div className={styles.loading}>
            <LoadingAnimation />

            <div className={styles.loadingTitle}>Patience chargement des résultats</div>

            <Tips />
          </div>
        )}
        {neverSearched && paragraphs === undefined && <Tips />}
        {(documentSearch.isIdle || documentSearch.isSuccess) &&
          paragraphs?.map(paragraph => {
            const title =
              paragraph.paragraph_metadata?.titles &&
              paragraph.paragraph_metadata?.titles.length > 0
                ? [
                    paragraph.paragraph_metadata?.titles[
                      paragraph.paragraph_metadata?.titles.length - 2
                    ],
                    paragraph.paragraph_metadata?.titles[
                      paragraph.paragraph_metadata?.titles.length - 1
                    ],
                  ].join('/')
                : paragraph.document_metadata?.display_name !== undefined &&
                  paragraph.document_metadata?.display_name.length > 0
                ? paragraph.document_metadata?.display_name
                : paragraph.document_metadata?.document_name?.replace(/\.[^/.]+$/, '') ?? '';
            return (
              <SearchResult
                context={paragraph.sentence ?? ''}
                title={title}
                localId={
                  localDocuments.find(doc => doc.remoteId === paragraph.document_metadata?.id)
                    ?.localId
                }
                tree={paragraph.paragraph_metadata?.titles?.join('/') ?? ''}
                rank={(paragraph.score?.value ?? 0) * 5}
                remoteId={paragraph.document_metadata?.id ?? ''}
                key={`$paragraph.document_metadata?.id}_${paragraph.sentence}`}
                page={paragraph.paragraph_metadata?.page ?? 0}
                highlight={paragraph.answer_text}
                text={paragraph.text}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SearchTab;
