import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IlluinSearchService } from 'src/Services/API';
import { Feedback } from 'src/Services/API/types';
import { MutationKeys } from '../types';

export const useDocumentFeedback = (text: string) => {
  return useMutation(
    [MutationKeys.feedBack, text],
    async (feedback: Feedback) => {
      const response = await IlluinSearchService.methodFeedbackPost({
        body: {
          user: 'neovie-app',
          feedback: {
            text,
            feedback,
          },
        },
      });
      return response;
    },
    {
      onSuccess: () => {
        toast.success('Feedback envoyé');
      },
      onError: () => {
        toast.warn('Erreur lors de la prise en compte du feedback');
      },
    }
  );
};
