import React from 'react';

import { Props } from './DocumentListPicker.types';
import styles from './DocumentListPicker.module.scss';
import { useHistory } from 'react-router-dom';

const DocumentListPicker = ({ title, category, subCategory, localId }: Props) => {
  const history = useHistory();
  return (
    <div
      className={styles.container}
      onClick={() => {
        history.push('/document/viewlocal/' + localId + '/1');
      }}
    >
      <div className={styles.leftBorder} />
      <div className={styles.text}>
        <div className={styles.category}>
          {category}
          {(subCategory?.length ?? 0) > 0 ? `/${subCategory}` : null}
        </div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default DocumentListPicker;
