import React, { useEffect, useRef, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import history from 'src/Utils/history';

import BottomNavigationBar from './Components/BottomNavigationBar';
import DocumentsCategoryList from './Containers/DocumentsCategoryList';
import DocumentsList from './Containers/DocumentsList';
import SearchTab from './Containers/SearchTab';
import { useDownloadDocuments } from './Hooks/IlluinSearch/useDownloadDocuments';

import { useServiceWorker } from './Hooks/ServiceWorker/useServiceWorker';
import { setupAPI } from './Services/API/APIConfigurator';
import ReactTooltip from 'react-tooltip';

import { ReactQueryDevtools } from 'react-query/devtools';
import InfoTab from './Containers/InfoTab';
import DocumentsListAll from './Containers/DocumentsListAll';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AndroidPDFViewer from './Components/AndroidPDFViewer';

import { AddIcon, AddToHomeScreen, ShareIcon, ThreeDots } from './Assets';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'iOS';
  }

  return 'unknown';
};

const getPWADisplayMode = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if ((navigator as any).standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
};

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/document/viewlocal/:localid/:page"
          render={() => <AndroidPDFViewer />}
        />
        <Route exact path="/document/view/:url/:page" render={() => <AndroidPDFViewer />} />
        <Route>
          <AppWithoutPDFViewer />
        </Route>
      </Switch>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Router>
  );
};

// We don't want to fetch token or refresh document list and trigger request when viewing PDF
function AppWithoutPDFViewer() {
  useEffect(() => setupAPI(), []);
  useDownloadDocuments();
  const serviceWorker = useServiceWorker();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [userOS] = useState(getMobileOperatingSystem());
  const [appDisplayMode] = useState(getPWADisplayMode());

  useEffect(() => {
    if (tooltipRef !== null && tooltipRef.current) {
      ReactTooltip.show(tooltipRef.current);
    }
  }, [tooltipRef]);

  useEffect(() => {
    if (serviceWorker?.isUpdateAvailable) {
      serviceWorker?.updateAssets();
      window.location.reload();
    }
  }, [serviceWorker, serviceWorker?.isUpdateAvailable]);

  return (
    <Route
      path="/"
      render={() => (
        <div className="App">
          <ReactQueryDevtools initialIsOpen={false} />
          <div className="content-holder">
            <Switch>
              {/* To hide a route behind authentication, use PrivateRoute instead of Route as shown below */}
              {/* <PrivateRoute exact path="/protected" component={null} /> */}
              <Route exact path="/search" render={() => <SearchTab />} />
              <Route exact path="/" render={() => <DocumentsList />} />
              <Route exact path="/documents/all" render={() => <DocumentsListAll />} />
              <Route exact path="/info" render={() => <InfoTab />} />
              <Route
                exact
                path="/documents/:category/:subCategory?"
                component={() => <DocumentsCategoryList />}
              />
            </Switch>
          </div>
          <BottomNavigationBar />
          <div
            ref={tooltipRef}
            style={{
              height: 0,
              width: '100%',
              position: 'fixed',
              bottom: userOS === 'iOS' ? 0 : undefined,
              top: userOS === 'Android' ? 0 : undefined,
            }}
            data-tip="tooltip"
          />

          {(userOS === 'iOS' || userOS === 'Android') && appDisplayMode === 'browser' && (
            <Popup
              open
              position="center center"
              contentStyle={{
                position: 'relative',
                outline: 'none',
                border: 'none',
                borderRadius: '8px',
                width: '100%',
                maxWidth: '20rem',
                backgroundColor: '#007cbf',
                padding: '2rem',
                color: 'white',
                fontSize: 17,
              }}
              overlayStyle={{
                backgroundColor: 'rgba(0,0,0,0.7)',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                overflowY: 'scroll',
                paddingTop: '2.5rem',
                paddingBottom: '2.5rem',
              }}
              modal
            >
              {userOS === 'iOS' ? (
                <div style={{ width: '15rem', textAlign: 'center' }}>
                  Pour installer l'application sur votre téléphone cliquez sur
                  <ShareIcon
                    style={{
                      height: '18px',
                      width: '18px',
                      fill: 'white',
                      verticalAlign: 'middle',
                      marginLeft: '0.3rem',
                      marginRight: '0.3rem',
                    }}
                  />
                  puis sur
                  <div>
                    <AddIcon
                      style={{
                        height: '20px',
                        width: '20px',
                        fill: 'white',
                        verticalAlign: 'middle',
                      }}
                    />
                    <span style={{ fontStyle: 'italic' }}>Sur l'écran d'accueil</span>
                  </div>
                </div>
              ) : (
                <div>
                  Pour installer l'application sur votre téléphone cliquez sur
                  <ThreeDots
                    style={{
                      height: '18px',
                      width: '18px',
                      fill: 'white',
                      verticalAlign: 'middle',
                    }}
                  />
                  puis sur
                  <div>
                    <AddToHomeScreen
                      style={{
                        height: '20px',
                        width: '20px',
                        fill: 'white',
                        verticalAlign: 'middle',
                      }}
                    />
                    <span style={{ fontStyle: 'italic' }}>Ajouter à l'écran d'accueil</span>
                    <br /> ou <br />
                    <AddToHomeScreen
                      style={{
                        height: '20px',
                        width: '20px',
                        fill: 'white',
                        verticalAlign: 'middle',
                      }}
                    />
                    <span style={{ fontStyle: 'italic' }}>Ajouter</span>
                    <br /> ou
                    <br />
                    <AddToHomeScreen
                      style={{
                        height: '20px',
                        width: '20px',
                        fill: 'white',
                        verticalAlign: 'middle',
                      }}
                    />
                    <span style={{ fontStyle: 'italic' }}>Installer</span>
                  </div>
                </div>
              )}
            </Popup>
          )}
        </div>
      )}
    />
  );
}

export default App;
