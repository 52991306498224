import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from './AndroidPDFViewer.module.scss';
import { FixedSizeList, FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import LoadingAnimation from '../LoadingAnimation';
import { useFetchDocumentData, useListLocalDocument } from 'src/Hooks/IlluinSearch';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useMatomo } from '@datapunt/matomo-tracker-react';

pdfjs.GlobalWorkerOptions.workerSrc = `${window.__RUNTIME_CONFIG__.PUBLIC_URL}/pdf.worker.js`;

const SPACE_BETWEEN_PAGES = 30;

const AndroidPDFViewer = () => {
  const { trackPageView } = useMatomo();
  const { url, page, localid } = useParams<{ url: string; page: string; localid: string }>();
  const [numPages, setNumPages] = useState<number>(1);
  const [error, setError] = useState<string>();

  const data = useFetchDocumentData(parseInt(localid));
  const documentInfo = useListLocalDocument(parseInt(localid));
  const history = useHistory();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (documentInfo?.name !== undefined) {
      trackPageView({ documentTitle: `pdf viewer : ${documentInfo?.name}` });
    }
  }, [trackPageView, documentInfo?.name]);

  const VirtualizedPage = ({ width, height }: { width: number; height: number }) => ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties | undefined;
  }) => {
    const pageNumber = index + 1;
    return (
      <div style={style} id={pageNumber.toString()} className={styles.page}>
        <Page
          loading={<div style={{ height, width, border: '3rem', borderColor: 'white' }} />}
          key={`page_${pageNumber}`}
          pageNumber={pageNumber}
          width={width}
        />
      </div>
    );
  };

  const VirtualizedList = ({ height, width }: { height: number; width: number }) => {
    const listRef = useRef<FixedSizeList>(null);
    useEffect(() => {
      if (listRef?.current && page !== undefined) {
        listRef?.current?.scrollToItem(parseInt(page) - 1, 'start');
      }
    }, [width]);

    return (
      <List
        ref={listRef}
        height={height}
        itemCount={numPages}
        itemSize={(width * 29.7) / 21 + SPACE_BETWEEN_PAGES}
        width={width}
        overscanCount={2}
      >
        {VirtualizedPage({ width, height: height - SPACE_BETWEEN_PAGES })}
      </List>
    );
  };

  return (
    <>
      <IconButton
        className={styles.goBackButton}
        aria-label="goBack"
        onClick={() => history.goBack()}
      >
        <ArrowBackIosIcon />
        Précédent
      </IconButton>
      <Document
        className={styles.container}
        file={
          error === undefined
            ? data
              ? data
              : decodeURIComponent(url)
            : window.__RUNTIME_CONFIG__.REACT_APP_FILE_URL +
              '/pdfs/' +
              documentInfo?.name.replace('.csv', '')
        }
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={er => {
          setError(JSON.stringify(er));
          console.error(JSON.stringify(er));
        }}
        error={<p>Chargement du PDF depuis internet</p>}
        loading={
          <div className={styles.loadingAnimation}>
            <LoadingAnimation />
          </div>
        }
      >
        <AutoSizer>
          {({ width, height }) => <VirtualizedList width={width} height={height} />}
        </AutoSizer>
      </Document>
    </>
  );
};

export default AndroidPDFViewer;
