import { IlluinSearchService, SearchResponse } from 'src/Services/API';
import { useMutation, UseMutationOptions } from 'react-query';
import { MutationKeys } from '../types';
import { useFetchProjectId } from './useFetchProjectId';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const useDocumentSearch = (
  extraOptions?: UseMutationOptions<SearchResponse, unknown, string>
) => {
  const projectId = useFetchProjectId();
  const { trackSiteSearch } = useMatomo();
  return useMutation(
    [MutationKeys.documentSearch],
    async (searchText: string) => {
      trackSiteSearch({ keyword: searchText });
      const response = await IlluinSearchService.methodSearchPost({
        body: {
          project_id: projectId,
          question: { text: searchText },
        },
      });
      return response;
    },
    extraOptions
  );
};
