import { useLiveQuery } from 'dexie-react-hooks';

import { db, Local, StoredDocument } from 'src/Services/DB';
import { useListDocuments } from './useListDocuments';

export const useListLocalDocuments = (category?: string, subCategory?: string) => {
  useListDocuments();

  const documents = useLiveQuery(() => db.documents.toArray() ?? []);

  const categoryDocuments =
    (category ? documents?.filter(document => document.tags?.includes(category)) : documents) ??
    ([] as Local<StoredDocument>[]);

  const subCategoryDocuments = subCategory
    ? categoryDocuments.filter(document => document.tags?.includes(subCategory))
    : categoryDocuments;

  return subCategoryDocuments;
};
