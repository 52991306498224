import React from 'react';

import { Props } from './Subtitle.types';
import styles from './Subtitle.module.scss';

const Subtitle = ({ text }: Props) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.text}>{text}</div>
        <div className={styles.arrowLeft} />
      </div>
    </div>
  );
};

export default Subtitle;
