import { useQuery, useQueryClient } from 'react-query';

import { IlluinSearchService } from 'src/Services/API';
import { db } from 'src/Services/DB';

import { QueryKeys } from '../types';
import { useFetchProjectId } from './useFetchProjectId';
import isEqual from 'lodash.isequal';

export const useListDocuments = () => {
  const projectId = useFetchProjectId();
  const queryClient = useQueryClient();

  const query = useQuery(
    [QueryKeys.listDocuments, projectId],
    async () => {
      if (!projectId) {
        return;
      }

      const documents = await IlluinSearchService.methodDocumentsGet({
        projectId: projectId,
      });

      if (documents.data === undefined) {
        return;
      }

      // save docs in db
      await Promise.all(
        documents.data.map(async document => {
          const localDocument = await db.documents.where({ remoteId: document.id }).first();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const tagsWithoutOrder = document.tags?.filter(tag => isNaN(tag as any));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const orderAsString = document.tags?.filter(tag => !isNaN(tag as any))?.[0];
          const order = orderAsString !== undefined ? parseInt(orderAsString) : undefined;

          if (!localDocument?.localId) {
            await db.documents.add({
              remoteId: document.id ?? '',
              tags: tagsWithoutOrder ?? [],
              name: document.document_name ?? 'nom inconnu',
              path: document.file_path ?? '',
              displayName: document.display_name,
              order,
            });
            await queryClient.invalidateQueries(QueryKeys.downloadDocuments);
          } else {
            if (
              !isEqual(localDocument?.tags, tagsWithoutOrder) ||
              localDocument?.name !== document.document_name ||
              localDocument?.displayName !== document.display_name ||
              localDocument?.path !== document.file_path ||
              localDocument?.order !== order
            ) {
              await db.documents.update(localDocument?.localId, {
                tags: tagsWithoutOrder,
                name: document.document_name,
                displayName: document.display_name,
                path: document.file_path,
                order,
              });
              await queryClient.invalidateQueries(QueryKeys.downloadDocuments);
            }
          }
        })
      );

      // remove unused docs
      (await db.documents.toArray()).forEach(document => {
        if (!documents.data?.find(d => d.id === document.remoteId)) {
          if (document.localId !== undefined) {
            console.log(`removing obsolete document ${document.remoteId}`);
            db.documents.delete(document.localId);
            db.documentData.where({ documentLocalId: document.localId }).delete();
          }
        }
      });

      return documents.data;
    },
    { staleTime: 60 * 60 * 1000, refetchInterval: 60 * 60 * 1000 }
  );

  return query.data;
};
