import React from 'react';

import { Button as MUIButton, ButtonProps, styled } from '@mui/material';

const Button = styled(MUIButton)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '16px',
  border: '1px solid',
  lineHeight: 1,
  backgroundColor: '#0075bf',
  borderColor: '#0075bf',
  fontFamily: 'Montserrat-Bold',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  height: '48px',
  '&:hover': {
    backgroundColor: '#008ae1',
    borderColor: '#008ae1',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#008ae1',
    borderColor: '#008ae1',
  },
  width: '100%',
});

const ErrorButton = styled(MUIButton)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '16px',
  border: '1px solid',
  lineHeight: 1,
  backgroundColor: '#D34E36',
  borderColor: '#D34E36',
  fontFamily: 'Montserrat-Bold',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  height: '48px',
  '&:hover': {
    backgroundColor: '#D75D47',
    borderColor: '#D75D47',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#D75D47',
    borderColor: '#D75D47',
  },
  width: '100%',
});

export const NeovieButton = (props: ButtonProps) => (
  <Button variant="contained" disableRipple {...props} />
);

export const NeovieErrorButton = (props: ButtonProps) => (
  <ErrorButton variant="contained" disableRipple {...props} />
);
