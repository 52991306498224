import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import styles from './LoadingAnimation.module.scss';
import { MagnifyingGlass } from 'src/Assets';

const LoadingAnimation = () => {
  useEffect(() => {
    gsap.registerPlugin(MotionPathPlugin);
    gsap.to('#ainimationTarget', {
      duration: 4,
      motionPath: {
        path: '#animationPath',
        align: '#animationPath',
        alignOrigin: [0.5, 0.5],
      },
      transformOrigin: '50% 50%',
      scale: 1,
      yoyo: false,
      repeat: -1,
      ease: 'power0',
    });
  }, []);

  return (
    <div className={styles.container}>
      <svg
        width="115px"
        height="109px"
        viewBox="0 0 240.6 223.7"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fill: 'transparent' }}
      >
        <path
          id="animationPath"
          className={styles.path}
          d="M42.6,118.4c0-21.8,13.8-55.3,34.9-70.5c29.6-21.3,63.9-26.7,96.2,1.9c27.1,24,32.1,61.8,25.2,82.5
              c-6.9,20.6-20.4,46.9-48.6,49c-22.4,1.7-48.4-19.1-47.5-37c0.9-17.9,10.4-28.4,24.1-27.6c13.7,0.8,21.9,20,18,35.4
              C138.6,174,108,178,85.7,172.4C60.7,166.2,42.6,140.2,42.6,118.4z"
        />
      </svg>
      <div className={styles.magnifyingGlass}>
        <MagnifyingGlass id="ainimationTarget" />
      </div>
    </div>
  );
};

export default LoadingAnimation;
