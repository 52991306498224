import React from 'react';

import { Props } from './BottomBarButton.types';
import styles from './BottomBarButton.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';

const BottomBarButton = ({ label, Icon, IconHighlighted, route }: Props) => {
  const location = useLocation();
  const history = useHistory();

  const isHighlighted =
    (route === '/' &&
      (location.pathname === route || location.pathname.startsWith('/documents'))) ||
    (route !== '/' && location.pathname.startsWith(route));

  return (
    <div
      className={styles.container}
      onClick={() => {
        history.push(route);
        window.scrollTo(0, 0);
      }}
      key={label}
    >
      {isHighlighted ? <IconHighlighted /> : <Icon />}
      <div className={classnames(styles.text, { [styles.selected]: isHighlighted })}>{label}</div>
    </div>
  );
};

export default BottomBarButton;
