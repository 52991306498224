import React from 'react';

import { ServiceWorkerProvider } from 'src/Hooks/ServiceWorker/useServiceWorker';
import { MatomoProvider } from './MatomoProvider';
import { ReactQueryProvider } from './ReactQuery';

import { ReduxProvider } from './Redux';

const Providers = ({ children }: { children: React.ReactElement }) => (
  <ServiceWorkerProvider>
    <MatomoProvider>
      <ReactQueryProvider>
        <ReduxProvider>{children}</ReduxProvider>
      </ReactQueryProvider>
    </MatomoProvider>
  </ServiceWorkerProvider>
);

export default Providers;
