import React from 'react';

import { Props } from './RankingIndicator.types';
import styles from './RankingIndicator.module.scss';
import classnames from 'classnames';

const RankingIndicator = ({ rank }: Props) => {
  return (
    <div className={styles.container}>
      {Array(5)
        .fill(undefined)
        .map((_, index) => {
          return (
            <div
              className={classnames(
                styles.singleIndicator,
                { [styles.grey]: index >= rank },
                { [styles.pink]: index < rank }
              )}
            />
          );
        })}
    </div>
  );
};

export default RankingIndicator;
