import React, { useCallback, useEffect, useState } from 'react';

import styles from './Tips.module.scss';
import { a, useSpring } from 'react-spring';
import useInterval from '@use-it/interval';
import classnames from 'classnames';
import illuin from '../../Assets/logos/illuin.png';
import CHRU from '../../Assets/logos/CHRU.png';
import casd from '../../Assets/logos/casd.png';
import innoveo from '../../Assets/logos/innoveo.png';
import winn from '../../Assets/logos/winn.png';
import gwennhadu from '../../Assets/logos/gwennhadu.png';

const loadingTexts = [
  "Quels sont les prérequis pour le test d'apnée ?",
  'Quels critères de sélection donneur DDAC M3 ?',
  "Quelles sont les pathologies qui contre indiquent un don d'organe ?",
  "Quelles religions autorisent le don d'organes ?",
  'Comment installer le donneur au BO ?',
  "Quelle dose d'héparine pour le clampage ?",
];

const Tips = () => {
  const [loadingTextIndex, setLoadingTextIndex] = useState([
    Math.floor(Math.random() * loadingTexts.length),
    Math.floor(Math.random() * loadingTexts.length),
  ]);
  const [loadingTextNextIndexToChange, setLoadingTextNextIndexToChange] = useState(0);

  const [flipped, setFlipped] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const changeLoadingText = useCallback(() => {
    let newLoadingTextIndex = 0;

    do {
      newLoadingTextIndex = Math.floor(Math.random() * loadingTexts.length);
    } while (
      newLoadingTextIndex === loadingTextIndex[0] ||
      newLoadingTextIndex === loadingTextIndex[1]
    );
    if (loadingTextNextIndexToChange === 0) {
      setLoadingTextIndex([newLoadingTextIndex, loadingTextIndex[1]]);
      setLoadingTextNextIndexToChange(1);
    } else {
      setLoadingTextIndex([loadingTextIndex[0], newLoadingTextIndex]);
      setLoadingTextNextIndexToChange(0);
    }
  }, [loadingTextIndex, loadingTextNextIndexToChange]);

  useInterval(() => {
    changeLoadingText();
  }, 5000);

  useEffect(() => {
    setFlipped(loadingTextNextIndexToChange === 0);
  }, [loadingTextIndex, loadingTextNextIndexToChange, setFlipped]);

  return (
    <div className={styles.container}>
      <div className={styles.loadingSubtitle}>
        Astuce : <br />
        Pour des réponses plus pertinentes, posez votre question en langage naturel. Par exemple :
      </div>
      <a.div
        className={classnames(styles.loadingText, styles.flippingCard)}
        style={{ opacity: opacity.to(o => 1 - o), transform, position: 'absolute' }}
      >
        {loadingTexts[loadingTextIndex[0]]}
      </a.div>
      <a.div
        className={classnames(styles.loadingText, styles.flippingCard)}
        style={{
          opacity,
          transform,
          rotateX: '180deg',
        }}
      >
        {loadingTexts[loadingTextIndex[1]]}
      </a.div>

      <div className={styles.loadingSubtitle}>
        Ce projet a été mené par la coordination du CHRU Brest et les partenaires suivants :
      </div>
      <div className={styles.logos}>
        <div className={styles.logoGrid}>
          <img src={illuin} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="chru" />
          <img src={CHRU} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="chru" />
          <img src={winn} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="winn" />

          <img src={innoveo} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="innoveo" />
          <img src={casd} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="casd" />
          <img src={gwennhadu} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="gwennhadu" />
        </div>
      </div>
    </div>
  );
};

export default Tips;
