import axios from 'axios';

import { requestInterceptor, errorInterceptor } from './Interceptors';

import { serviceOptions } from '.';

export function setupAPI() {
  axios.defaults.baseURL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;
  axios.interceptors.request.use(requestInterceptor);
  axios.interceptors.response.use(undefined, errorInterceptor);
  serviceOptions.axios = axios;
}
