type DocumentStructure = {
  [key: string]: {
    title: string;
    children?: DocumentStructure;
  };
};

export const documentStructure: DocumentStructure = {
  EME: {
    title: 'EME',
    children: {
      Réanimation: { title: 'Réanimation' },
      'Bloc opératoire': { title: 'Bloc opératoire' },
      Coordination: { title: 'Coordination' },
    },
  },
  M3: {
    title: 'DDAC-M3',
  },
  Proches: {
    title: 'Proches',
  },
  Législation: {
    title: 'Législation',
  },
};
