import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DocumentListPicker from 'src/Components/DocumentListPicker';
import { useListLocalDocuments } from 'src/Hooks/IlluinSearch';
import { documentStructure } from 'src/Services/API/Documents';

import styles from './DocumentsListAll.module.scss';

const DocumentsListAll = () => {
  const { trackPageView } = useMatomo();
  const documents = useListLocalDocuments();
  const history = useHistory();

  useEffect(() => {
    trackPageView({ documentTitle: 'document list all page' });
  }, [trackPageView]);

  return (
    <div className={styles.container}>
      <div className={styles.title} onClick={() => history.push('/')}>
        Fiches synthèse
      </div>
      <div className={styles.list}>
        {documents
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map(doc => {
            //find category of document through tags
            let categoryName = '';
            const categoryTagList = Object.keys(documentStructure ?? {});

            categoryTagList.forEach(tag => {
              if (doc.tags.includes(tag)) {
                categoryName = tag;
              }
            });

            // find subcategory of document through tags
            let subCategoryName = '';
            const subCategoryTagList = Object.keys(documentStructure[categoryName]?.children ?? {});

            subCategoryTagList.forEach(tag => {
              if (doc.tags.includes(tag)) {
                subCategoryName = tag;
              }
            });

            return (
              <DocumentListPicker
                title={(doc.displayName ?? doc.name ?? 'sans titre').replace(/\.[^/.]+$/, '')}
                category={categoryName}
                subCategory={subCategoryName}
                localId={doc.localId ?? 0}
              />
            );
          })}
      </div>
    </div>
  );
};

export default DocumentsListAll;
