import React from 'react';
import {
  Fiches,
  FichesHighlighted,
  Loupe,
  LoupeHighlighted,
  Pratique,
  PratiqueHighlighted,
} from 'src/Assets';
import BottomBarButton from './BottomBarButton';

import styles from './BottomNavigationBar.module.scss';

const bottomBarTabs = [
  {
    label: 'Recherche',
    route: '/search',
    Icon: Loupe,
    IconHighlighted: LoupeHighlighted,
  },
  { label: 'Fiches', route: '/', Icon: Fiches, IconHighlighted: FichesHighlighted },
  { label: 'Liens', route: '/info', Icon: Pratique, IconHighlighted: PratiqueHighlighted },
];

const BottomNavigationBar = () => {
  return (
    <div className={styles.container}>
      {bottomBarTabs.map(tab => (
        <BottomBarButton {...tab} key={tab.label} />
      ))}
    </div>
  );
};

export default BottomNavigationBar;
