import { combineReducers } from '@reduxjs/toolkit';

// ILLUIN-STARTER-TODO: remove below import
import { catsReducer } from './Cats/Slice';
import { AppDispatch } from './store';

const rootReducer = combineReducers({
  // ILLUIN-STARTER-TODO: remove line below
  cats: catsReducer,
  // insert your own reducers here
  // see https://redux-toolkit.js.org/api/createSlice for documentation
  // see https://redux-toolkit.js.org/api/createAsyncThunk for async actions handling
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  dispatch: AppDispatch;
  state: RootState;
  extra?: unknown;
  rejectValue?: unknown;
}

export default rootReducer;
