import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CatsState, Cat } from './Types';

const initialState: CatsState = {
  cats: [{ id: 0, name: 'Felix', age: 1, breed: '' }],
};

let nextId = 1;

export const { reducer: catsReducer, actions: catsActions } = createSlice({
  name: 'cats',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Omit<Cat, 'id'>>) => {
      state.cats.push({ ...action.payload, id: nextId });
      nextId += 1;
    },
    remove: (state, action: PayloadAction<{ id: number }>) => {
      state.cats = state.cats.filter(cat => cat.id !== action.payload.id);
    },
  },
});
