import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DocumentListPicker from 'src/Components/DocumentListPicker';
import HorizontalPicker from 'src/Components/HorizontalPicker';
import Subtitle from 'src/Components/Subtitle';
import { useListLocalDocuments } from 'src/Hooks/IlluinSearch';
import { documentStructure } from 'src/Services/API/Documents';

import styles from './DocumentsCategoryList.module.scss';

const DocumentsCategoryList = () => {
  const { trackPageView } = useMatomo();
  const history = useHistory();
  const { category, subCategory } = useParams<{ category: string; subCategory?: string }>();

  const documents = useListLocalDocuments(category, subCategory);

  const orderedDocuments = useMemo(
    () => documents.sort((a, b) => ((a.order ?? 0) > (b.order ?? 0) ? 1 : -1)),
    [documents]
  );

  useEffect(() => {
    trackPageView({ documentTitle: 'document category list page' });
  }, [trackPageView]);

  return (
    <div className={styles.container}>
      <div className={styles.title} onClick={() => history.push('/')}>
        Fiches synthèse
      </div>
      <Subtitle text={documentStructure[category]?.title ?? ''} />
      <div style={{ height: '9px' }} />
      {documentStructure[category]?.children && (
        <HorizontalPicker
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          choices={Object.entries(documentStructure[category].children!).map(subCategory => ({
            value: subCategory[1].title,
            onClick: () => history.replace(`/documents/${category}/${subCategory[0]}`),
          }))}
          selected={subCategory && documentStructure[category]?.children?.[subCategory]?.title}
        />
      )}
      <div className={styles.list}>
        {orderedDocuments.map(doc => {
          // find subcategory of document through tags
          const subCategoryTagList = Object.keys(documentStructure[category]?.children ?? {});
          let subCategoryName = '';

          subCategoryTagList.forEach(tag => {
            if (doc.tags.includes(tag)) {
              subCategoryName = tag;
            }
          });

          return (
            <DocumentListPicker
              title={(doc.displayName ?? doc.name ?? 'sans titre').replace(/\.[^/.]+$/, '')}
              category={documentStructure[category]?.title}
              subCategory={subCategoryName}
              localId={doc.localId ?? 0}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsCategoryList;
