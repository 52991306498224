import React from 'react';

import { Props } from './HorizontalPicker.types';
import styles from './HorizontalPicker.module.scss';
import classnames from 'classnames';

const HorizontalPicker = ({ choices, selected }: Props) => {
  return (
    <div className={styles.container}>
      {choices.map(choice => (
        <span className={styles.separator}>
          <span
            className={classnames(styles.choice, { [styles.selected]: selected === choice.value })}
            onClick={choice.onClick}
          >
            {choice.value}
          </span>
        </span>
      ))}
    </div>
  );
};

export default HorizontalPicker;
