import React from 'react';

import { Props } from './SearchResult.types';
import styles from './SearchResult.module.scss';
import RankingIndicator from '../RankingIndicator';
import FeedbackInput from '../FeedbackInput';
import classnames from 'classnames';
import { useDocumentFeedback } from 'src/Hooks/IlluinSearch/useDocumentFeedback';
import { Feedback } from 'src/Services/API/types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const getHighlightedText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          className={classnames({
            [styles.highlightedContent]: part.toLowerCase() === highlight.toLowerCase(),
          })}
        >
          {part}
        </span>
      ))}
    </span>
  );
};

const SearchResult = ({ title, context, tree, rank, highlight, text, page, localId }: Props) => {
  const { mutate } = useDocumentFeedback(text);
  const history = useHistory();

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (localId !== undefined) {
          history.push('/document/viewlocal/' + localId + '/' + page);
        } else {
          toast.warn(
            "Le document que vous souhaitez consulter n'a pas encore été téléchargé. Veuillez réessayer plus tard"
          );
        }
      }}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>
        {highlight ? getHighlightedText(context, highlight) : context}
      </div>
      <RankingIndicator rank={rank} />
      <div className={styles.tree}>{tree}</div>
      <FeedbackInput
        onFeedbackOk={() => mutate(Feedback.ok)}
        onFeedbackNok={() => mutate(Feedback.nok)}
      />
    </div>
  );
};

export default SearchResult;
