import Dexie from 'dexie';

export type Local<T, K extends keyof T | never = never> = Omit<T, K> &
  Partial<Pick<T, K>> & { localId?: number };

export interface StoredDocument {
  remoteId: string;
  tags: string[];
  name: string;
  displayName?: string;
  path: string;
  order?: number;
}

export interface StoredDocumentData {
  documentLocalId: number;
  data: Blob;
}

class NeovieDB extends Dexie {
  documents: Dexie.Table<Local<StoredDocument>, number>;
  documentData: Dexie.Table<Local<StoredDocumentData>, number>;

  constructor() {
    super('NeovieLocal');
    this.version(3)
      .stores({
        documents: '++localId, remoteId',
      })
      .upgrade(async tx => {
        const localDocument = await db.documents
          .where({ remoteId: '4e952069-8909-b01b-3b3b-1b3c72c25e7c' })
          .first();
        if (localDocument) {
          return tx.table('documents').delete(localDocument?.localId);
        }
      });
    this.version(4)
      .stores({ documentData: '++localId, &documentLocalId' })
      .upgrade(tx => tx.table('documents').clear());

    this.documents = this.table('documents');
    this.documentData = this.table('documentData');
  }
}

export const db = new NeovieDB();
