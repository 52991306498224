/** Generate by swagger-axios-codegen */
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class IlluinSearchService {
  /**
   * Get Status
   */
  static getStatusGet(options: IRequestOptions = {}): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodLoginPost(
    params: {
      /** requestBody */
      body?: UserDetails;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodTokenGet(options: IRequestOptions = {}): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/token';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodDocumentsGet(
    params: {
      /**  */
      projectId: string;
      /**  */
      documentId?: string;
      /**  */
      tagList?: any | null[];
      /**  */
      offset?: number;
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/documents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        project_id: params['projectId'],
        document_id: params['documentId'],
        tag_list: params['tagList'],
        offset: params['offset'],
        limit: params['limit'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodDocumentPost(options: IRequestOptions = {}): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/document';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodDocumentDelete(
    params: {
      /**  */
      projectId: string;
      /**  */
      documentId?: string;
      /**  */
      tagList?: any | null[];
      /**  */
      offset?: number;
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/document';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = {
        project_id: params['projectId'],
        document_id: params['documentId'],
        tag_list: params['tagList'],
        offset: params['offset'],
        limit: params['limit'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodTagsGet(
    params: {
      /**  */
      projectId: string;
      /**  */
      documentId?: string;
      /**  */
      tagList?: any | null[];
      /**  */
      offset?: number;
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TagListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tags';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        project_id: params['projectId'],
        document_id: params['documentId'],
        tag_list: params['tagList'],
        offset: params['offset'],
        limit: params['limit'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodTagsPost(
    params: {
      /**  */
      projectId: string;
      /**  */
      documentId?: string;
      /**  */
      tagList?: any | null[];
      /**  */
      offset?: number;
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tags';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        project_id: params['projectId'],
        document_id: params['documentId'],
        tag_list: params['tagList'],
        offset: params['offset'],
        limit: params['limit'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodTagsDelete(
    params: {
      /**  */
      projectId: string;
      /**  */
      documentId?: string;
      /**  */
      tagList?: any | null[];
      /**  */
      offset?: number;
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tags';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = {
        project_id: params['projectId'],
        document_id: params['documentId'],
        tag_list: params['tagList'],
        offset: params['offset'],
        limit: params['limit'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodFeedbackPost(
    params: {
      /** requestBody */
      body?: FeedbackDetails;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feedback';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodSearchPost(
    params: {
      /** requestBody */
      body?: SearchParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodMetadataCountPost(
    params: {
      /** requestBody */
      body?: SearchParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/metadata_count';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodQuestionHistoryPost(options: IRequestOptions = {}): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/question_history';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodUserPost(
    params: {
      /** requestBody */
      body?: NewUserParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodUsersGet(
    params: {
      /**  */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodAcronymGet(
    params: {
      /**  */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AcronymListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/acronym';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodAcronymPost(
    params: {
      /**  */
      projectId: string;
      /**  */
      state?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/acronym';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { project_id: params['projectId'], state: params['state'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodAcronymDelete(
    params: {
      /**  */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/acronym';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodAbbreviationGet(
    params: {
      /**  */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AbbreviationListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/abbreviation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodAbbreviationPost(
    params: {
      /**  */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/abbreviation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodAbbreviationDelete(
    params: {
      /**  */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/abbreviation';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodSpecificVocabularyGet(
    params: {
      /**  */
      projectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpecificVocabularyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/specific_vocabulary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodSpecificVocabularyPost(
    params: {
      /**  */
      projectId: string;
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/specific_vocabulary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Method
   */
  static methodSpecificVocabularyDelete(
    params: {
      /**  */
      projectId: string;
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HTTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/specific_vocabulary';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { project_id: params['projectId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface Abbreviation {
  /**  */
  abbreviation: string;

  /**  */
  signification: string;

  /**  */
  project_id?: string;
}

export interface AbbreviationListResponse {
  /**  */
  status?: number;

  /**  */
  message?: string;

  /**  */
  data?: Abbreviation[];
}

export interface Acronym {
  /**  */
  acronym: string;

  /**  */
  signification: string;

  /**  */
  state?: string;

  /**  */
  project_id?: string;
}

export interface AcronymListResponse {
  /**  */
  status?: number;

  /**  */
  message?: string;

  /**  */
  data?: Acronym[];
}

export interface Body_method_document_post {
  /**  */
  project_id: string;

  /**  */
  document_metadata?: string;

  /**  */
  file: string;
}

export interface DateFilter {
  /**  */
  lt?: Date;

  /**  */
  gt?: Date;
}

export interface DocumentFilter {
  /**  */
  allowed_document_ids?: string[];

  /**  */
  publication_date?: DateFilter;

  /**  */
  source?: string[];

  /**  */
  document_type?: string[];
}

export interface DocumentListResponse {
  /**  */
  status?: number;

  /**  */
  message?: string;

  /**  */
  data?: LoadedDocument[];
}

export interface DocumentMetadata {
  /**  */
  author?: string;

  /**  */
  publication_date?: Date;

  /**  */
  document_type?: string;

  /**  */
  source?: string;

  /**  */
  document_name?: string;

  display_name?: string;

  /**  */
  id?: string;

  /**  */
  external_id?: string;
}

export interface FeedbackDetails {
  /**  */
  user: string;

  /**  */
  feedback: object;
}

export interface HTTPResponse {
  /**  */
  status: number;

  /**  */
  message: string;

  /**  */
  data?: object;
}

export interface HTTPValidationError {
  /**  */
  detail?: ValidationError[];
}

export interface LoadedDocument {
  /**  */
  id?: string;

  /**  */
  document_name: string;

  /**  */
  extension?: string;

  /**  */
  file_path?: string;

  /**  */
  json_path?: string;

  /**  */
  project_id?: string;

  /**  */
  tags?: string[];

  display_name?: string;
}

export interface NewUserParams {
  /**  */
  username: string;

  /**  */
  password: string;

  /**  */
  project_id: string;
}

export interface ParagraphFilter {
  /**  */
  page?: number[];
}

export interface ParagraphMetadata {
  /**  */
  page?: number;

  /**  */
  x_0?: number;

  /**  */
  x_1?: number;

  /**  */
  y_0?: number;

  /**  */
  y_1?: number;

  /**  */
  titles?: string[];

  /**  */
  original_text?: string;
}

export interface QuestionMetadata {
  /**  */
  question_type: QuestionType;

  /**  */
  is_keyword?: boolean;
}

export interface ResponseTime {
  /**  */
  global_time?: number;

  /**  */
  retriever?: number;

  /**  */
  reranker?: number;

  /**  */
  question_answering?: number;
}

export interface SearchAnswer {
  /**  */
  text: string;

  /**  */
  processed_text?: string;

  /**  */
  id?: string;

  /**  */
  score?: SearchScore;

  /**  */
  group?: SearchGroup;

  /**  */
  paragraph_metadata?: ParagraphMetadata;

  /**  */
  document_metadata?: DocumentMetadata;

  /**  */
  answer_text?: string;

  /**  */
  answer_start_index?: number;

  /**  */
  answer_start_index_in_sentence?: number;

  /**  */
  sentence?: string;

  /**  */
  feedback?: string;

  /**  */
  question?: string;

  /**  */
  response_time?: ResponseTime;
}

export interface SearchGroup {
  /**  */
  id: string;

  /**  */
  name?: string;

  /**  */
  parameters?: string[];

  /**  */
  score?: number;

  /**  */
  rank?: number;

  /**  */
  inner_rank?: number;
}

export interface SearchParams {
  /**  */
  project_id: string;

  /**  */
  question: SearchQuestionWithGroup;
}

export interface SearchQuestionWithGroup {
  /**  */
  text: string;

  /**  */
  id?: string;

  /**  */
  limit?: number;

  /**  */
  offset?: number;

  /**  */
  original?: string;

  /**  */
  question_metadata?: QuestionMetadata;

  /**  */
  document_filter?: DocumentFilter;

  /**  */
  paragraph_filter?: ParagraphFilter;

  /**  */
  groupByDocument?: boolean;
}

export interface SearchResponse {
  /**  */
  status?: number;

  /**  */
  message?: string;

  /**  */
  data: SearchResponseData;
}

export interface SearchResponseData {
  /**  */
  question: SearchQuestionWithGroup;

  /**  */
  paragraphs: SearchAnswer[];

  /**  */
  aggregated?: SearchAnswer;
}

export interface SearchScore {
  /**  */
  name: string;

  /**  */
  value: number;

  /**  */
  weight?: number;

  /**  */
  rank?: number;

  /**  */
  details?: SearchScore[];
}

export interface SpecificVocabularyResponse {
  /**  */
  status?: number;

  /**  */
  message?: string;

  /**  */
  data?: SpecificWord[];
}

export interface SpecificWord {
  /**  */
  word: string;

  /**  */
  project_id?: string;
}

export interface TagListResponse {
  /**  */
  status?: number;

  /**  */
  message?: string;

  /**  */
  data?: string[];
}

export interface User {
  /**  */
  id?: string;

  /**  */
  username: string;

  /**  */
  password: string;

  /**  */
  is_admin?: boolean;
}

export interface UserDetails {
  /**  */
  username: string;

  /**  */
  password: string;
}

export interface UserListResponse {
  /**  */
  status?: number;

  /**  */
  message?: string;

  /**  */
  data?: User[];
}

export interface ValidationError {
  /**  */
  loc: string[];

  /**  */
  msg: string;

  /**  */
  type: string;
}

export enum QuestionType {
  'extractive' = 'extractive',
  'abstractive' = 'abstractive',
  'boolean' = 'boolean',
}
