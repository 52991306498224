export enum QueryKeys {
  searchDocument = 'searchDocument',
  listDocuments = 'listDocuments',
  authenticate = 'authenticate',
  downloadDocuments = 'downloadDocuments',
}

export enum MutationKeys {
  documentSearch = 'documentSearch',
  feedBack = 'feedback',
}
