import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './SearchResultLoading.module.scss';

const SearchResultLoading = () => {
  return (
    <div className={styles.container}>
      <ContentLoader height="7rem" width="100%" uniqueKey="positionStudyCard">
        <rect x="0%" y="5" rx="4" ry="4" width="100%" height="15" />
        <rect x="0%" y="25" rx="4" ry="4" width="80%" height="15" />
        <rect x="0%" y="55" rx="4" ry="4" width="100%" height="8" />
        <rect x="0%" y="70" rx="4" ry="4" width="85%" height="8" />
        <rect x="0%" y="85" rx="4" ry="4" width="95%" height="8" />
        <rect x="0%" y="100" rx="4" ry="4" width="45%" height="8" />
      </ContentLoader>
    </div>
  );
};

export default SearchResultLoading;
