import { useQuery } from 'react-query';
import { db } from 'src/Services/DB';
import { useListLocalDocuments } from '.';

export const useDownloadDocuments = () => {
  const documents = useListLocalDocuments();

  useQuery(
    ['downloadDocuments', ...documents.map(document => document.remoteId)],
    () => {
      Promise.all(
        documents.map(async document => {
          if (
            document.localId !== undefined &&
            (await db
              .table('documentData')
              .where('documentLocalId')
              .equals(document.localId)
              .count()) === 0
          ) {
            console.log(`fetching ${document.displayName}`);
            const data = await fetch(
              window.__RUNTIME_CONFIG__.REACT_APP_FILE_URL +
                '/pdfs/' +
                document.name.replace('.csv', '')
            );
            const blob = await data.blob();

            if (blob.type !== 'application/pdf') {
              throw new Error('file is not a pdf');
            }

            try {
              await db.documentData.add({
                documentLocalId: document.localId ?? -1,
                data: blob,
              });
            } catch (ex) {
              console.log('error when adding document ' + document.displayName);
            }
          }
        })
      );
    },
    { staleTime: 60 * 60 * 1000 }
  );
};
