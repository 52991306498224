import { useQuery } from 'react-query';
import { IlluinSearchService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchProjectId = () => {
  const query = useQuery(
    [QueryKeys.authenticate],
    async () => {
      const response = await IlluinSearchService.methodTokenGet();
      return response?.data.project_id;
    },
    { staleTime: 60 * 60000, refetchOnWindowFocus: false, refetchOnReconnect: false }
  );

  return query.data ?? '';
};
