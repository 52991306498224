import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const ServiceWorkerContext = createContext<{
  isUpdateAvailable: boolean;
  updateAssets: () => void;
} | null>(null);

export const ServiceWorkerProvider = ({ children }: { children: ReactNode }) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker | null>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
      onWaiting: waiting => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      },
    });
  }, []);

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an incosistent state
    waitingServiceWorker?.addEventListener('statechange', event => {
      if ((event.target as ServiceWorker | undefined)?.state === 'activated') {
        window.location.reload();
      }
    });
  }, [waitingServiceWorker]);

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (waitingServiceWorker) {
          // We send the SKIP_WAITING message to tell the Service Worker
          // to update its cache and flush the old one
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
      },
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
  return useContext(ServiceWorkerContext);
};
