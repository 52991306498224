import React from 'react';
import { MatomoProvider as MP, createInstance } from '@datapunt/matomo-tracker-react';

const instance = createInstance({
  urlBase: 'https://matomo.search-neovie-testing.illuin-tech.com/',
  siteId: 1,
  disabled: process.env.NODE_ENV === 'development',
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

export const MatomoProvider = ({ children }: { children: React.ReactNode }) => (
  <MP value={instance}>{children}</MP>
);
